import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBkDdnetsklCuHWbcllC5ewj1kh2lPfV7o",
    authDomain: "mapleminutes-9464b.firebaseapp.com",
    projectId: "mapleminutes-9464b",
    storageBucket: "mapleminutes-9464b.appspot.com",
    messagingSenderId: "208047781444",
    appId: "1:208047781444:web:0a7a8528cc87d9548e6e11",
    measurementId: "G-S8E262Z02J"
};
  
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export default db;
  