import React from 'react'
import Insta from '../assets/insta.png'
import LinkedIn from '../assets/linkedIn.png'
import Phone from '../assets/Phone.png'

export default function Mobile_left() {
  return (
    <div className='mobile_left'>
        <div className='mob_ma'>
            Ma
        </div>
        <div className='mob_mi'>
            Mi
        </div>
        <div className='mob_l1'>
            Stay informed effortlessly with our app, distilling top news into concise summaries
        </div>
        <div className='mob_phone'>
            <img src={Phone} alt='Phone' height={350}/>
        </div>
        <div className='mob_usOn'>
            Follow us on
        </div>
        
    </div>
  )
}
