import React from 'react'
import grid from './assets/grid.png'
import Desktop_left from './components/Desktop_left'
import Desktop_right from './components/Desktop_right'

export default function Desktop() {
  return (
    <div className='desktop'>

        <div className="circle1"></div>
        <div className="circle2"></div>
        <div className="circle3"></div>
        <div className='gridImage'>
          <img width={'400px'} height={'400px'} src={grid} alt="Description of Image" />
        </div>

        <div className='left'>
            <Desktop_left/>
        </div>
        
        <div className='right'>
            <Desktop_right/> 
        </div>
        
    </div>
  )
}
