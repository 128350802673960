import React from 'react'
import Mobile_left from './components/Mobile_left'
import grid from './assets/grid.png'
import Mobile_right from './components/Mobile_right'

export default function Mobile() {
  return (
    <div className='mobile'>
      <div className="mob_circle1"></div>
      <div className='mob_gridImage'>
        <img width={'150px'} height={'150px'} src={grid} alt="Description of Image" />
      </div>
      <div className='left'>
            <Mobile_left/>
        </div>
        
        <div className='right'>
            <Mobile_right/>
        </div>
    </div>
  )
}
