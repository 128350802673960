import React from 'react'
import db from '../fireconfig';
import { doc, setDoc, getDoc } from "firebase/firestore"; 
import Insta from '../assets/insta.png'
import LinkedIn from '../assets/linkedIn.png'

export default function Mobile_right() {

    const [email, setEmail] = React.useState('');
    const [isVisible, setIsVisible] = React.useState(false);
    const [msg, setMsg] = React.useState("");

    const showComponent = () => {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 2000); // Set to hide after 2 seconds
      };

    const printEmail = async ()  =>{
    // console.log(email);
    // check if the email format is correct 
    const emailRegex = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = emailRegex.test(email);
    // if yes - YEET THE MAIL 
    if (isValid === true){
        // console.log("Congrats ur not a retard")
        setMsg("You have been added to our News Letter");
        const docRef = doc(db, "NewsLetter", "EmailList");
        const docSnap = await getDoc(docRef);
        const tempList = docSnap.data().Emails;
        tempList.push(email);
        console.log(tempList);
        await setDoc( doc(db, "NewsLetter", "EmailList"),{Emails:tempList} );
    }
    // else
    else { 
        setMsg("Email is incorrect")
    }
    // reset text field 
    setEmail("");
    showComponent();
    }

    function handleEmail(e) {
    setEmail(e.target.value);
    }

    return (
        <div className='mobile_right'>
            <div className='mob_powered'>
                Powered by AI
            </div>
            <div className='mob_ple'>
                ple 
            </div>
            <div className='mob_nutes'>
                nutes
            </div>
            <div className='mob_newLetter'>
                <div>
                    Ensure you never miss the heart of the story. Dive in today!
                </div>
            </div>
            <div className='mob_input'>
                <input 
                    type="text" 
                    className="mob_textfield-style" 
                    placeholder="janedoe@email.com"
                    onChange={handleEmail}
                    value={email}
                />
                <div onClick={printEmail}>
                    <button className="mob_custom-button">
                        Sign Up
                    </button>
                </div>
            </div>
            <div className={`fade-component ${isVisible ? 'visible' : ''}`}>
            <div className={`bottom_popup ${msg === "Email is incorrect" ? 'mob_bottom_popup_error' : 'mob_bottom_popup_success'} `}>
                {msg} 
            </div>
            </div>
            <div className='mob_socialLogo'>
                <div className='mob_insta'>
                    <img src={Insta} alt="Insta" height={50}/>
                </div>
                <div>
                <img src={LinkedIn} alt="LinkedIn" height={50}/>
                </div>
            </div>
        </div>
    )
}
