import React from 'react';
import Insta from '../assets/insta.png'
import LinkedIn from '../assets/linkedIn.png'
import Phone from '../assets/Phone.png'

export default function Desktop_right() {
  return (
    <div className='desktop_right'>
        <div className='powered'>
            Powered by AI
        </div>
        <div className='ple'>
            ple Minutes
        </div>
        <div className='essence'>
            Get the essence summarized
        </div>

        <div className='follow'>
            <div className='usOn'>
                Follow Us On
            </div>
            <div className='insta'>
                <img src={Insta} alt='insta'/>
            </div>
            <div>
                <img src={LinkedIn} alt='linkedIn'/>
            </div>
        </div>
        
        
        <div className='phone'>
            <img src={Phone} alt='Phone' height={600}/>
        </div>

        

    </div>
  )
}
