import React, { useState, useEffect } from 'react';
import Mobile from './Mobile';
import Desktop from './Desktop';
import './App.css'

const App = () => {
  const [isMobile, setMobile] = useState();

  
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    if (getDeviceType() == "mobile"){
      setMobile(true);
    }
    else 
      setMobile(false);
  },[])

  function getDeviceType() {
    const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    return isMobile ? "mobile" : "desktop";
  }  
  
  return (
    <div>
      {
        isMobile ? 
          <div>
            <Mobile/>
          </div> 
        :
          <div>
            <Desktop/>
          </div>
      }
    </div>
    );
}

export default App;
